// ----------------------------- Debounce

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

// var myEfficientFn = debounce(function() {
//     // All the taxing stuff you do
// }, 250);
//
// window.addEventListener('resize', myEfficientFn);

// SRC: https://davidwalsh.name/javascript-debounce-function

function debounce(func, wait, immediate) {
	let timeout;
	return function () {
		let context = this, args = arguments;
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
// ----------------------- Archive Posts
function archivePostsInit() {
	const archivePosts = document.querySelectorAll('.m__archive-posts');

	if (archivePosts) {
		for (let i = 0; i < archivePosts.length; i++) {
			let archivePost = archivePosts[i];

			const column = archivePost.querySelector('.c__card--post');
			const gutter = parseInt(window.getComputedStyle(column, null).getPropertyValue("padding-left")) * 2;

			new Isotope(archivePost.querySelector('.isotope'), {
				itemSelector: '.c__card--post',
				percentPosition: true,
				masonry: {
					gutter: gutter,
					columnWidth: archivePost.querySelector('.c__card--post .inner')
				}
			});
		}
	}
}

// ----------------------------- Navigation

function navigationInit() {

	// ------- Toggle sub-menus

	let parentListItems = document.querySelectorAll('.c__menu--header .menu-item-has-children');

	function initSubMenus() {
		for (let i = 0; i < parentListItems.length; i++) {
			// Add click toggle for small screens
			parentListItems[i].addEventListener('click', toggleSubMenu);
		}
	}

	initSubMenus();
	window.addEventListener('resize', initSubMenus);

	function toggleSubMenu() {
		this.classList.toggle('active');
	}

	// ------- Toggle navigation and search panels

	let navButton = document.querySelector('.toggle-navigation'),
		navPanel = document.querySelector('.m__header-navigation-sm'),
		searchButton = document.querySelector('.toggle-search'),
		searchPanel = document.querySelector('.m__header-search-panel'),
		header = document.querySelector('.site-main'),
		body = document.querySelector('body');

	// Navigation
	//navButton.addEventListener('click', toggleNavigation);

	function toggleNavigation() {
		// Close search
		searchPanel.classList.remove('active');
		searchButton.classList.remove('active');
		// Open navigation
		navPanel.classList.toggle('active');
		body.classList.toggle('menu-active');
		header.classList.toggle('menu-active');
		this.classList.toggle('active');
	}

	// Search
	//searchButton.addEventListener('click', toggleSearch);

	function toggleSearch() {
		// Close Navigation
		navPanel.classList.remove('active');
		navButton.classList.remove('active');
		header.classList.remove('menu-active');
		// Open search
		searchPanel.classList.toggle('active');
		this.classList.toggle('active');
	}

}


// ----------------------------- Pagination single

function initPaginationSingle() {
	let pagination = document.querySelector('.c__pagination-single');

	if (pagination) {
		let togglePagination = debounce(function () {

			if (window.pageYOffset > 600) {
				pagination.style.opacity = 1;
			} else {
				pagination.style.opacity = 0;
			}
		}, 250);

		window.addEventListener('scroll', togglePagination);
	}
}

function headerInit() {
	let new_scroll_position = 0;
	let last_scroll_position;
	let header = document.querySelector('.site-main');
	let headerHeight = header.offsetHeight;

	function toggleHeader() {
		last_scroll_position = window.scrollY;

		if (new_scroll_position < last_scroll_position && last_scroll_position > 80) {
			header.classList.add("hidden");
		} else if (new_scroll_position > last_scroll_position) {
			header.classList.remove("hidden");
		}

		new_scroll_position = last_scroll_position;
	}

	if (header) {
		window.addEventListener('scroll', toggleHeader);
	}
}


// ----------------------------- Strike through

function initStrikeThrough() {
	let els = document.querySelectorAll('.strike-through');

	for (let i = 0; i < els.length; i++) {
		let el = els[i],
			after = el.querySelector('.after'),
			adjancent = el.offsetWidth,
			opposite = el.offsetHeight,
			hypontenuse = Math.sqrt((adjancent * adjancent) + (opposite * opposite)),
			rotation = Math.asin(opposite / hypontenuse) * (180 / Math.PI);

		after.style.width = hypontenuse + 'px';
		after.style.transform = `rotate(-${rotation}deg)`;
		after.style.opacity = 1;
	}
}


// ----------------------- Carousel

function carouselInit() {
	const carousels = document.querySelectorAll('.m__carousel .flickity-carousel');
	const flktyOptions = {
		contain: true,
		pageDots: false,
		wrapAround: true,
	};

	if (carousels) {
		for (let i = 0; i < carousels.length; i++) {
			let carousel = carousels[i];
			new Flickity(carousel, flktyOptions);
		}
	}
}


// ----------------------- Related Content
function relatedContentInit() {
	const relatedContents = document.querySelectorAll('.m__related-content');

	if (relatedContents) {
		for (let i = 0; i < relatedContents.length; i++) {
			new Isotope(relatedContents[i].querySelector('.isotope'), {
				itemSelector: '.c__card--post',
			});
		}
	}
}


// ----------------------- Sound player

class SoundPlayer {
	constructor(options = {}) {
		this.player = options.player;
		this.isPlaying = false;
		this.playButton = this.player.querySelector('.play');
		this.muteButton = this.player.querySelector('.mute');
		this.sourceFile = this.player.querySelector('.audio-source');
		this.duration = this.player.querySelector('.time .duration');
		this.currentTime = this.player.querySelector('.time .current');
		this.sliderButton = this.player.querySelector('.progressbar .slider');
		this.progressBar = this.player.querySelector('.progressbar .progress');

		this.addEventListeners();
	}

	addEventListeners() {
		this.sourceFile.addEventListener('canplay', this.init.bind(this));
		this.sourceFile.addEventListener('ended', this.pause.bind(this));
		this.sourceFile.addEventListener('timeupdate', this.updateProgress.bind(this));

		this.playButton.addEventListener('click', this.togglePlay.bind(this));
		this.muteButton.addEventListener('click', this.toggleSound.bind(this));

		this.sliderButton.addEventListener('mouseup', this.mouseUpHandler.bind(this));
		this.sliderButton.addEventListener('touchend', this.mouseUpHandler.bind(this));
		this.sliderButton.addEventListener('mousedown', this.mouseDownHandler.bind(this));
		this.sliderButton.addEventListener('touchstart', this.mouseDownHandler.bind(this));
	}

	getDuration() {
		const duration = this.sourceFile.duration;
		const minutes = Math.floor(duration / 60);
		const secondsInt = Math.floor(duration - minutes * 60);
		const seconds = secondsInt.toString().length < 2 ? '0' + secondsInt : secondsInt;

		return (minutes && seconds) ? minutes + ':' + seconds : '00:00';
	}

	getCurrentTime() {
		const currentTime = this.sourceFile.currentTime;
		const currentHour = parseInt(currentTime / 3600) % 24;
		const currentMinute = parseInt(currentTime / 60) % 60;
		const currentSecondsLong = currentTime % 60;
		const currentSeconds = currentSecondsLong.toFixed();

		return (currentHour > 0 ? currentHour + ':' : '') + (currentMinute < 10 ? "0" + currentMinute : currentMinute) + ":" + (currentSeconds < 10 ? "0" + currentSeconds : currentSeconds);
	}

	setCurrentTime(sliderButtonValue) {
		this.sourceFile.currentTime = parseFloat(sliderButtonValue) * this.sourceFile.duration / 100;
	}

	mouseUpHandler(event) {
		this.trackSeek(event);
	}

	mouseDownHandler() {
		this.sourceFile.pause();
		this.playButton.classList.add('paused');
	}

	pause() {
		this.isPlaying = false;
		this.sourceFile.pause();
		this.playButton.classList.add('paused');
	}

	play() {
		if (this.sourceFile.ended) {
			this.sourceFile.currentTime = 0;
		}

		this.isPlaying = true;
		this.sourceFile.play();
		this.playButton.classList.remove('paused');
	}

	togglePlay() {
		if (this.sourceFile.paused) {
			this.play();
		}
		else {
			this.pause();
		}
	}

	toggleSound() {
		if (this.sourceFile.muted) {
			this.sourceFile.muted = false;
			this.muteButton.classList.remove('on');
		}
		else {
			this.sourceFile.muted = true;
			this.muteButton.classList.add('on');
		}
	}

	updateProgress() {
		const percentage = parseFloat(((100 / this.sourceFile.duration) * this.sourceFile.currentTime).toFixed(2));

		this.sliderButton.value = percentage;
		this.progressBar.value = percentage < 50 ? percentage + 0.35 : percentage;  // +0.25 to avoid white space with the slider
		this.currentTime.innerText = this.getCurrentTime();
	}

	trackSeek(event) {
		this.setCurrentTime(event.currentTarget.value);

		if (this.isPlaying) {
			this.play();
		}
	}

	init() {
		this.duration.innerText = this.getDuration();
		this.currentTime.innerText = this.getCurrentTime();
	}
}

function soundPlayersInit() {
	const players = document.querySelectorAll('.c__audio-player');

	for (let i = 0; i < players.length; i++) {
		new SoundPlayer({ player: players[i] }).init();
	}
}

//**** BB Modules Functions ****//

// Create dynamically span
function createElSpan(childContainer, i) {
	let el = document.createElement('span');
	el.setAttribute('class', `el-background`);
	if (i !== undefined && i !== null) el.classList.add(`el-${i}`);

	childContainer.appendChild(el);
}

// Add Span Background on Become Member
function moduleBecomeMember() {
	const container = document.querySelector('.become-a-member');

	if (container) {
		const childContainer = document.querySelector('.become-a-member .fl-row-content-wrap');
		createElSpan(childContainer);
	}
}

// Add Span Background on Our Story
function moduleOurStory() {
	const container = document.querySelector('.our-story');

	if (container) {
		let childContainer = document.querySelector('.our-story .fl-row-content-wrap');
		createElSpan(childContainer);
	}
}

// Add Span Background on FAQ
function moduleFAQ() {
	const container = document.querySelector('.faq-block');

	if (container) {
		const childContainer = document.querySelector('.faq-block .fl-row-content-wrap > .fl-row-content');

		for (let i = 0; i < 2; i++) {
			createElSpan(childContainer, i);
		}
	}
}

// Add Span Background on Make Your Diaries
function moduleMakeYourDiaries() {
	const container = document.querySelector('.make-your-diaries');

	if (container) {
		let childContainer = document.querySelector('.make-your-diaries .fl-row-content-wrap > .fl-row-content');

		for (let i = 0; i < 4; i++) {
			createElSpan(childContainer, i);
		}
	}
}

// Add Span Background on Quote
function moduleQuote() {
	const container = document.querySelector('.float-els');

	if (container) {
		let childContainer = document.querySelector('.float-els .fl-row-content-wrap > .fl-row-content');

		for (let i = 0; i < 2; i++) {
			createElSpan(childContainer, i);
		}
	}
}

// Add Span Element on Left
function addElementOnLeft() {
	const container = document.querySelector('.float-left-el');

	if (container) {
		let childContainer = document.querySelector('.float-left-el .fl-row-content-wrap > .fl-row-content');
		createElSpan(childContainer);
	}
}

function headerElementColor() {
	const container = document.querySelector('.block-welcome.bleeding-photo');

	if (container) {
		const childContainer = document.querySelector('.block-welcome.bleeding-photo > .fl-row-content-wrap');
		const leftContainer = document.querySelector('.block-welcome.bleeding-photo > .fl-row-content-wrap .left-column').offsetHeight;

		// Get height from the element
		const height = leftContainer + 120;

		// Get background color on CSS
		const style = getComputedStyle(childContainer);
		const backgroundColor = style.backgroundColor;

		// Create a element
		for (let i = 0; i < 2; i++) {
			let el = document.createElement('span');
			el.setAttribute('class', `el-${i}`);

			if (i == 0) {
				el.classList.add('el-header-background');
				el.setAttribute('style', `height:${height}px; background-color:${backgroundColor};`);
			}

			childContainer.appendChild(el);
		}
	}
}

function updatingHeight() {
	let container = document.querySelector('.block-welcome.bleeding-photo .el-header-background');

	if (container) {
		const leftContainer = document.querySelector('.block-welcome.bleeding-photo > .fl-row-content-wrap .left-column').offsetHeight;
		const childContainer = document.querySelector('.block-welcome.bleeding-photo > .fl-row-content-wrap');

		// Get background color on CSS
		const style = getComputedStyle(childContainer);
		const backgroundColor = style.backgroundColor;

		// Get height from the element
		const elHeight = leftContainer + 120;
		container.setAttribute('style', `height:${elHeight}px; background-color:${backgroundColor};`);

	}
}


// On document ready

document.addEventListener('DOMContentLoaded', function () {
	navigationInit();
	initPaginationSingle();
	//headerInit();
	//datePickerInit();
	soundPlayersInit();

	moduleBecomeMember();
	moduleFAQ();
	moduleMakeYourDiaries();
	moduleQuote();
	moduleOurStory();
	addElementOnLeft();
	headerElementColor();
});

window.addEventListener('resize', function () {
	updatingHeight();
});

window.addEventListener('load', () => {
	carouselInit();
	relatedContentInit();
	archivePostsInit();
	initStrikeThrough();
});

/* Dropdowns */

(function ($) {

	$(window).on("load resize", function () {
		var bwidth = $(window).width();
		console.log(bwidth);
		$(".learn-drop").on('click', function () {
			if (910 < bwidth) {
				$('.dropdown').not('.learn').slideUp('fast');
				$('.dropdown.learn').slideDown('fast');
				$(".dropdown").each(function () {
					$(this).find('.menu-switcher').hide();
					$(this).find('.menu-switcher').first().css('display', 'flex');
				});
			} else {
				$('.dropdown').not('.learn').fadeOut('fast');
				$('.dropdown.learn').fadeIn('fast');
			}
		});
		$(".lead-drop").on('click', function (e) {
			if (910 < bwidth) {
				$('.dropdown').not('.lead').slideUp('fast');
				$('.dropdown.lead').slideDown('fast');
				$(".dropdown").each(function () {
					$(this).find('.menu-switcher').hide();
					$(this).find('.menu-switcher').first().css('display', 'flex');
				});
			} else {
				$('.dropdown').not('.lead').fadeOut('fast');
				$('.dropdown.lead').fadeIn('fast');
			}
		});
		$(".connect-drop").on('click', function () {
			if (910 < bwidth) {
				$('.dropdown').not('.connect').slideUp('fast');
				$('.dropdown.connect').slideDown('fast');
				$(".dropdown").each(function () {
					$(this).find('.menu-switcher').hide();
					$(this).find('.menu-switcher').first().css('display', 'flex');
				});
			} else {
				$('.dropdown').not('.connect').fadeOut('fast');
				$('.dropdown.connect').fadeIn('fast');
			}
		});
		$(".dropdown").each(function () {
			$(this).find('.menu-switcher').hide();
			$(this).find('.menu-switcher').first().css('display', 'flex');
		});
		$(".site-main").on('mouseleave', function () {
			$('.dropdown').slideUp('fast');
		});
	});

	$(document).on('ready', function () {
		console.log('document ready');
		$(".dropdown .menu > li > a").on('mouseover', function () {
			var $selector = $(this).text() + '-group';
			var $trimmed = $selector.trim().replace(/\s/g, '-').toLowerCase();
			var $list_id = '#' + $trimmed;
			console.log($list_id);
			$('.menu-switcher').not($list_id).hide();
			$($list_id).css('display', 'flex');
		});

		$(".mobmenu").on('click', function () {
			$(this).toggleClass('active').toggleClass('not-active');
			$('nav').fadeToggle();
			$('.search-cover').fadeOut();
			$('.dropdown').fadeOut();
		});

		$(".mobile-heading, .mobile-back").on('click', function () {
			$('.dropdown').fadeOut('fast');
		});

		$(".search-icon").on('click', function () {
			$('.search-cover').fadeIn();
			if ($(window).width() < 910) {
				$('nav').fadeOut();
				$(".mobmenu").removeClass('active');
			}
		});

		$(".search-cover .close").on('click', function () {
			$('.search-cover').fadeOut();
			$(".mobmenu").removeClass('active');
		});

		/* Notice Banner JS */
		// Check if the user already closed the notice bar.
		if (!window.localStorage.getItem('notice_bar_closed')) {
			$('.notice-bar').removeClass('hidden');
		}

		$(".notice-bar span.close").on('click', function (e) {
			// Save to LocalStorage
			window.localStorage.setItem('notice_bar_closed', true);
			$('.notice-bar').addClass('hidden');
		});

	});

})(jQuery);
